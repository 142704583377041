import { apiService } from "./apiService";

export const authService = apiService.injectEndpoints({
    endpoints: (builder) => ({
        isCompanyAuthorized: builder.query({
            query: () => 'isCompanyAuthorize'
        }),
        authorize: builder.query({
            query: ({ appToken = '', prefix = '', token, locationId, key, ...params }) => {

                let headers = {}

                if (token && locationId) {
                    headers = {
                        Authorization: token,
                        locationId
                    }
                }

                return {
                    url: `${prefix}authorize/${appToken}`,
                    params: { ...params, appId: 961 },
                    headers
                }
            }
        }),
        authorizeAccountId: builder.query({
            query: ({ appToken = '', prefix = '', token, locationId, key, ...params }) => {

                let headers = {}

                if (token && locationId) {
                    headers = {
                        Authorization: token,
                        locationId
                    }
                }

                return {
                    url: `${prefix}authorizeAccountId/${appToken}/`,
                    params: { ...params },
                    headers
                }
            }
        }),
        authorizeOAuth: builder.query({
            query: ({  code, state }) => {
                return {
                    url: 'company/authorize',
                    params: { code, state }
                }
            }
        }),
        updateDomain: builder.mutation({
            query: (body) => {
                return {
                    url: 'updateDomain/',
                    method: 'PUT',
                    body
                }
            }
        }),
        login: builder.mutation({
            query: (body) => {
                return {
                    url: 'login/',
                    method: 'POST',
                    body
                }
            }
        }),
        signup: builder.mutation({
            query: (body) => {
                return {
                    url: 'signup/',
                    method: 'POST',
                    body
                }
            }
        }),
        logout: builder.query({
            query: () => 'logout/'
        }),
        resetPassword: builder.mutation({
            query: (body) => {
                return {
                    url: 'resetPwd/',
                    method: "POST",
                    body
                }
            }
        }),
        changePassword: builder.mutation({
            query: (body) => {
                return {
                    url: 'changePwd/',
                    method: "POST",
                    body
                }
            }
        }),
        recreate: builder.query({
            query: () => 'company/recreate/'
        }),
        connectionMode: builder.mutation({
            query: (payload) => {
                return {
                    url: "connectionMode/",
                    ...payload
                }
            }
        })
    })
});

export const {
    useIsCompanyAuthorizedQuery,
    useAuthorizeQuery,
    useAuthorizeAccountIdQuery,
    useLogoutQuery,
    useLoginMutation,
    useRecreateQuery,
    useConnectionModeMutation,
    useSignupMutation,
    useAuthorizeOAuthQuery,
    useUpdateDomainMutation,
    useResetPasswordMutation,
    useChangePasswordMutation,
} = authService;