import { Alert, Card, Spin, Steps } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from '@lightspeed/flame/Button';
import "./style.css"
import { companyLogo, softwareImages } from '../../utils/helpers/softwareImages';
import { useTranslation } from 'react-i18next';
import { getConnectionModeName } from '../../utils/helpers/commonHelpers';
import { useConnectionModeMutation, useIsCompanyAuthorizedQuery, useRecreateQuery } from "../../services/authService";
import Lottie from "react-lottie";
import loadingAnim from "../statics/loading.json";
import connectedAnim from "../statics/connected.json";
import { AiOutlineArrowRight } from "react-icons/ai"
import { setConnectionSw, setIsReconnecting, setLocationList } from "../../slices/authSlice";
import _, { isEmpty, some } from "lodash";
import { resetSyncPage } from "../../slices/syncSlice";
import { resetDashboardPage, setGlobalLoading } from "../../slices/dashboardSlice";
import Logout from "../../components/custom/logout";
import { useLocationData } from "../../hooks/useLocation";
import { Box } from '@lightspeed/flame/Core';
import { IconChevronLeft } from "@lightspeed/flame/Icon/ChevronLeft";
import Copyrights from "../../components/custom/copyright";
import { TextLink, Text } from '@lightspeed/flame/Text';
import { Flex } from '@lightspeed/flame/Core';
import { DeactivateButton } from "../../components/Dashboard/AccountingSoftware";
import { SessionTimeoutError } from '../../components/custom/sessionTimeout';
import { Form, Formik, useField } from "formik";
import { Input } from "@lightspeed/flame/Input";
import * as Yup from 'yup';

export const UserOnboard = ({ navigate }) => {

    const { isReconnecting, locationList, locationid, swList } = useSelector(state => state.auth)
    const { connectionMode } = useSelector(state => state.dashboard)
    const [currentStep, setCurrentStep] = useState(isReconnecting)
    const [isDisconnecting, setIsDisconnecting] = useState(false)
    const [sw, setSw] = useState()
    const [pwd, setPwd] = useState()
    const { isMultiLocation, currentLocation } = useLocationData()
    const [OauthPopup, setPopup] = useState()
    const dispatch = useDispatch();

    useEffect(() => {
        if (isReconnecting) {
            if (isEmpty(connectionMode)) {
                setSw(currentLocation?.connectionMode ?? "")
            } else {
                setSw(connectionMode)
            }
        } else {
            dispatch(resetSyncPage())
            dispatch(resetDashboardPage())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getContext = () => {
        switch (currentStep) {
            case 0:
                return <StepOne onConnect={(sw) => {
                    if (sw === "Microsoft GP" || sw === "NetSuite") {
                        setCurrentStep(1.5)
                        dispatch(setConnectionSw(sw))
                    } else {
                        setCurrentStep(1)
                    }

                    setSw(sw)
                }} />
            case 1:
                return <StepTwo onNewPopup={setPopup} sw={sw} isDisconnecting={isDisconnecting} isReconnecting={isReconnecting} onSuccess={(pwd) => {
                    setCurrentStep(2)
                    setPwd(pwd)
                }} />
            case 1.5:
                return <StepTwoInput sw={sw} onConnectionSuccess={() => {
                    setCurrentStep(2)
                }} />
            case 2:
                return <StepThree sw={sw} pwd={pwd} moveToDash={() => {
                    let list = locationList.slice()

                    if (list?.length > 0 && locationid !== "") {
                        list = list?.map(loc => {
                            const obj = { ...loc }
                            if (loc?.id === locationid) {
                                obj.showSetupAccountingButton = false
                                obj.connectionMode = sw
                            }

                            return obj
                        })
                    }

                    dispatch(setLocationList(list))
                    dispatch(setGlobalLoading(true))
                    navigate("../dashboard")
                }} />
            default:
                return <></>
        }
    }

    const BackToLocation = () => {

        const handleClick = () => {
            navigate("../select-location")
        }

        if (isMultiLocation && currentStep === 0) {
            return (
                <>
                    <Box mt="1.125rem" mb="0.8rem">
                        <TextLink mb="0.75rem" onClick={handleClick}>
                            <IconChevronLeft color="blue-500"
                                style={{
                                    width: '0.75rem',
                                    height: '0.75rem',
                                    mr: '0.563rem',
                                    marginTop: '0.2rem',
                                    marginBottom: '0.2rem',
                                    marginRight: '0.375rem',
                                    marginLeft: '1rem',
                                }}
                            />
                            Back to Locations
                        </TextLink>
                    </Box>
                </>
            )
        }

        return <></>
    }


    const handleDisconnect = () => {
        setIsDisconnecting(true)
        OauthPopup?.close()
    }

    const handleOnDisconnectSuccess = () => {
        setCurrentStep(0)
        dispatch(setIsReconnecting(0))
        setIsDisconnecting(false)
        setSw()
        setPwd()
    }

    const getPageSize = () => {
        switch (currentStep) {
            case 0:
                return (swList?.length < 4)
            case 1:
                return true
            case 1.5:
                return false
            case 2:
                return true
            default:
                return true
        }
    }

    return (
        <>
            <SessionTimeoutError navigate={navigate} />
            <Spin spinning={isDisconnecting} size="large" tip="Disconnecting....">
                <BackToLocation />
                <div className="steps">
                    <Steps current={currentStep} items={Array(3).fill(0).map((step, key) => ({ title: "", key }))} />
                    <Logout navigate={navigate} />
                    {currentStep !== 0 && <DeactivateButton description="Accounting Software will be disconnected from the current location." navigate={navigate} isLoading={isDisconnecting} isAppSubmitting={isDisconnecting} onClickCallBack={handleDisconnect} onSuccessCallback={handleOnDisconnectSuccess} />}
                </div>
                {getContext()}
            </Spin>
            <Copyrights isFullPage={getPageSize()} />
        </>
    )
}

const StepOne = ({ onConnect }) => {

    const { fullName, swList: sw } = useSelector(state => state.auth)

    const { t } = useTranslation();

    return (
        <>
            <div className="greet">
                Welcome to {fullName} Accounting!
            </div>
            <div className="cards">
                {
                    sw?.map((item, key) => (
                        <Card hoverable className="card" key={key}>
                            <Flex flexDirection={"column"} alignItems={"center"}>
                                <img className="logo" src={softwareImages[item?.id]} alt='' />
                                <Text fontWeight="bolder" fontSize="large" mt=".7rem" className="swName">{getConnectionModeName(t, item?.id)}</Text>
                                <p className="desp">
                                    Cloud accounting software is an easy solution to small business bookkeeping.
                                </p>
                                <div className="btns">
                                    <p className="lmbtn" onClick={() => window.open('https://resto-support.lightspeedhq.com/hc/en-us/articles/1260804626929', '_blank')}>
                                        Learn More
                                    </p>
                                    <Button variant="secondary" fill onClick={() => onConnect(item?.id)}>
                                        Connect
                                    </Button>
                                </div>
                            </Flex>
                        </Card>
                    ))
                }
            </div>
        </>
    )
}

const StepTwo = ({ sw, onSuccess, isReconnecting, onNewPopup }) => {

    const { fullName } = useSelector(state => state.auth)

    const { t } = useTranslation();

    const [postConnection, { isLoading: isFetchingLink, isError: isOAuthLinkError }] = useConnectionModeMutation()

    const [startAuthorize, setAuthorize] = useState(false)

    const [pwd, setQboPwd] = useState()

    const [popup, setPopup] = useState()

    const [isPopupBlockError, setPopupBlockError] = useState(false)

    const [key] = useState(Math.random())

    const { isSuccess, isLoading, isFetching, isError } = useIsCompanyAuthorizedQuery({ key }, { skip: !startAuthorize })

    const { data, refetch, isSuccess: isRecreateDone, isError: isReCreateOAuthLinkError, isLoading: isRecreatingLink, isFetching: isReFetchingOauth } = useRecreateQuery({ key }, { skip: !Boolean(isReconnecting) })

    const dispatch = useDispatch()

    const noPopup = sw === "QB Desktop Edition"

    useEffect(() => {
        !isReconnecting && connect()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isRecreateDone) {
            makeConnection(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecreateDone])

    useEffect(() => {
        if (isSuccess) {
            dispatch(setIsReconnecting(0))
            onSuccess(pwd)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess])

    useEffect(() => {
        if (popup) {
            const interval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(interval)
                    setAuthorize(true)
                }
            })
        }
    }, [popup])

    const makeConnection = (res) => {
        if (!(noPopup || !_.isEmpty(res.qwcDownloadURL))) {
            var new_tab = window.open()
            if (new_tab !== null) {
                if (_.isEmpty(res.qwcDownloadURL)) {
                    new_tab.location.href = res.onlineAuthenticationUrl
                    setPopup(new_tab)
                    onNewPopup(new_tab)
                }
            } else {
                throwPopupError()
            }
        } else {
            setAuthorize(true)

            setQboPwd(res.qwcDownloadPassword)
            handleDownload(res.qwcDownloadURL)
        }
    }

    const handleDownload = (link) => {
        const a = document.createElement('a')
        a.href = link
        a.target = "_blank"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a);
    }

    const throwPopupError = () => {
        setPopupBlockError(true)
    }

    const connect = () => {
        if (popup) {
            popup?.close()
        }

        var new_tab = null;

        if (!noPopup) {
            new_tab = window.open()
        }

        setPopupBlockError(false)

        setAuthorize(false)
        postConnection({
            body: {
                connectionMode: sw
            },
            method: "POST"
        })
            .unwrap()
            .then((res) => {
                if (new_tab === null && !noPopup) {
                    new_tab = window.open()
                }

                if (!(noPopup || !_.isEmpty(res.qwcDownloadURL))) {
                    if (new_tab !== null) {
                        if (_.isEmpty(res.qwcDownloadURL)) {
                            new_tab.location.href = res.onlineAuthenticationUrl
                            new_tab.focus()
                            setPopup(new_tab)
                            onNewPopup(new_tab)
                        }
                    } else {
                        throwPopupError()
                    }
                } else {
                    setAuthorize(true)

                    setQboPwd(res.qwcDownloadPassword)
                    handleDownload(res.qwcDownloadURL)
                }
            })
            .catch((d) => {
                new_tab.close()
            })
    }

    const reInitiateRecreate = () => {
        if (popup) {
            popup?.close()
        }

        setPopupBlockError(false)

        var new_tab = null;

        if (!noPopup) {
            new_tab = window.open()
        }

        refetch()
            .then(() => {
                let res = data;

                if (new_tab === null && !noPopup) {
                    new_tab = window.open()
                }

                if (!(noPopup || !_.isEmpty(res.qwcDownloadURL))) {
                    if (new_tab !== null) {
                        if (_.isEmpty(res.qwcDownloadURL)) {
                            new_tab.location.href = res.onlineAuthenticationUrl
                            new_tab.focus()
                            setPopup(new_tab)
                            onNewPopup(new_tab)
                        }
                    } else {
                        throwPopupError()
                    }
                } else {
                    setAuthorize(true)

                    setQboPwd(res.qwcDownloadPassword)
                    handleDownload(res.qwcDownloadURL)
                }
            })
            .catch(() => {
                new_tab.close()
            })
    }

    return (
        <>
            <p className="greet">Connecting with Accounting Software...</p>
            {getConnectionAnim(companyLogo[fullName], softwareImages[sw], loadingAnim, { height: 100, width: 300 })}
            {(isFetchingLink || isRecreatingLink || isReFetchingOauth) &&
                <Flex justifyContent={"center"} style={{ marginTop: "2rem" }}>
                    <Flex mt=".3rem">
                        <Spin />
                    </Flex>
                    <Text pl=".5rem" fontWeight="bolder" fontSize="x-large">Generating authorizing Link...</Text>
                </Flex>
            }
            {(isLoading) && !(isFetchingLink || isRecreatingLink || isReFetchingOauth) &&
                <Flex justifyContent={"center"} style={{ marginTop: "2rem" }}>
                    <Flex mt=".6rem">
                        <Spin />
                    </Flex>
                    <Text pl=".5rem" pt=".3rem" fontWeight="bolder" fontSize="x-large">Checking Connection...</Text>
                </Flex>
            }
            {(isError) &&
                <Flex justifyContent={"center"}>
                    <Alert type="error" style={{ width: "620px", paddingBlock: "3px", marginTop: "2rem", textAlign: "center" }} description={
                        <div style={{ fontSize: "16px", fontWeight: "500" }}>
                            Connection to Accounting Software is failed, please click <TextLink variant="link" className="here-link" style={{ padding: 0, margin: 0 }} onClick={isReconnecting ? reInitiateRecreate : connect} type="link">here</TextLink> to reinitiate.
                        </div>
                    } />
                </Flex>
            }
            {(isPopupBlockError) &&
                <Flex justifyContent={"center"}>
                    <Alert type="error" style={{ width: "620px", paddingBlock: "3px", marginTop: "2rem", textAlign: "center" }} description={
                        <div style={{ fontSize: "16px", fontWeight: "500" }}>
                            Popup has been blocked, please click <TextLink variant="link" className="here-link" style={{ padding: 0, margin: 0 }} onClick={isReconnecting ? reInitiateRecreate : connect} type="link">here</TextLink> to open new window.
                        </div>
                    } />
                </Flex>
            }
            {(isOAuthLinkError || isReCreateOAuthLinkError) &&
                <Flex justifyContent={"center"}>
                    <Alert type="error" style={{ width: "300px", paddingBlock: "3px", marginTop: "2rem", textAlign: "center" }} description={
                        <div style={{ fontSize: "16px", fontWeight: "500" }}>
                            Failed to generate authorizing link.
                        </div>
                    } />
                </Flex>
            }
            {!(isLoading || isFetching || isFetchingLink || isRecreatingLink || isReFetchingOauth || isError || isOAuthLinkError || isReCreateOAuthLinkError || isPopupBlockError) &&
                <div className="text-2">
                    Click <TextLink variant="link" style={{ padding: 0, margin: 0 }} className="here-link" onClick={isReconnecting ? reInitiateRecreate : connect}>here</TextLink> if you don’t see a sign-in window.
                </div>
            }
            <div className="text-block">
                <div className="text-1">
                    Sign in to {getConnectionModeName(t, sw)} using an account with admin privileges to authorize {fullName} Accounting to share your accounting data.
                </div>
                {pwd && <div className="pass">
                    Password is {pwd}
                </div>}
                <div className="text-3">
                    Having issues signing in to your <span className="here-link" onClick={() => window.open('https://resto-support.lightspeedhq.com/hc/en-us/articles/1260804626929', '_blank')}>
                        {getConnectionModeName(t, sw) + "  "}account?
                    </span> Learn more about <span className="here-link" onClick={() => window.open('https://resto-support.lightspeedhq.com/hc/en-us/articles/1260804626929', '_blank')}>{getConnectionModeName(t, sw)} Requirements</span> at the {fullName} Help Center.
                </div>
            </div>
        </>
    )
}

const StepTwoInput = ({ sw, onConnectionSuccess }) => {

    const { fullName, locationId } = useSelector(state => state.auth)

    const [postConnection, { isLoading }] = useConnectionModeMutation()

    let credentialFields = [
        {
            name: "Client ID",
            field: "clientId"
        },
        {
            name: "Client Secret",
            field: "clientSecret"
        },
        {
            name: "Access Token",
            field: "accessToken"
        },
        {
            name: "Access Secret",
            field: "accessTokenSecret"
        }
    ]

    if (sw === "NetSuite") {
        credentialFields = [
            {
                name: "Account Number",
                field: "accountNo"
            },
            {
                name: "Role",
                field: "role"
            },
            ...credentialFields
        ]
    } else if (sw === "Microsoft GP") {
        credentialFields = [
            {
                name: "Domain",
                field: "domain"
            },
            {
                name: "Role ID",
                field: "roleId"
            },
            ...credentialFields
        ]
    }

    const getInitialValues = () => {
        let fields = {}
        for (let i = 0; i < credentialFields.length; i++) {
            fields[credentialFields[i]?.field] = ''
        }
        return fields;
    }

    const createValidationSchema = () => {
        let validation = {}
        for (let i = 0; i < credentialFields.length; i++) {
            validation[credentialFields[i]?.field] = Yup.string()
                .required(`${credentialFields[i]?.name} is required`)
        }
        return Yup.object().shape(validation);
    };

    const TextInput = (props) => {
        const [field, meta] = useField(props);
        let status = {};
        if (meta.touched && meta.error) {
            status = { type: 'error', message: meta.error };
        }
        return (
            <div style={{ width: "100%", marginTop: "20px" }}>
                <Input
                    status={status} {...field} {...props}
                    value={field.value === undefined ? '' : field.value}
                    autoComplete='none'
                    style={{ width: "100%" }}
                />
            </div>
        )
    };

    const CredentialsForm = ({ formik }) => {
        let isDisabled = (!isEmpty(formik.errors) || some(formik.values, isEmpty));

        return (<Form>
            <>
                {
                    credentialFields?.map(({ name, field: fieldName }) => {
                        return (
                            <TextInput formik={formik} label={name} id={fieldName} name={fieldName} placeholder={`Enter ${name}`} />
                        )
                    })
                }
            </>
            <Flex justifyCenter>
                <Button loading={isLoading} variant="secondary" fill disabled={isDisabled} type="submit" style={{ marginTop: "35px" }}>{`Authorize with ${sw}`}</Button>
            </Flex>
        </Form>)
    }

    return (<>
        {getConnectionAnim(companyLogo[fullName], softwareImages[sw], loadingAnim, { height: 100, width: 300 })}
        <Card className="cred-card" title={<Text fontWeight='bold' style={{ display: "flex", justifyContent: "center" }} fontSize='medium'>Share the credentials with integration developer to authorize the connection with {fullName}...</Text>} type="inner">
            <>
                <Formik
                    initialValues={getInitialValues()}
                    validationSchema={createValidationSchema()}
                    onSubmit={(values) => {
                        postConnection({
                            body: {
                                connectionMode: sw,
                                ...values,
                                locationId
                            },
                            method: "POST"
                        })
                            .unwrap()
                            .then(() => {
                                onConnectionSuccess()
                            })
                    }}
                    validateOnChange
                >
                    {formik =>
                        <CredentialsForm formik={formik} loading={isLoading} />
                    }
                </Formik>
            </>
        </Card>
    </>)
}

const StepThree = ({ sw, moveToDash, pwd }) => {

    const { fullName } = useSelector(state => state.auth)

    return (
        <>
            {getConnectionAnim(companyLogo[fullName], softwareImages[sw], connectedAnim, { height: 200, width: 200 }, false)}
            <div className="step-3">
                {pwd && <div className="pass">
                    Password is : {pwd}
                </div>}
                <p className="success-text">Connection  Successful!</p>
                <p className="dash-btn" onClick={moveToDash}>
                    Move to Dashboard
                    <AiOutlineArrowRight className="right-icon" />
                </p>
            </div>
        </>
    )
}

const getConnectionAnim = (companyLogo, swImage, anim, style, loop = true) => {
    return (
        <>
            <div className="conn-loader">
                <img src={companyLogo} alt="" className="company-logo" />
                <div className="lottie">
                    <Lottie
                        options={{
                            loop,
                            autoplay: true,
                            animationData: anim,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        className="lottie"
                        {...style}
                        background="transparent"
                    />
                </div>
                <img className="company-logo" src={swImage} alt='' />
            </div>
        </>
    )
}