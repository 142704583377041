import { setLocation, setLocationList } from "../../redux/reducer/locationReducer";
import { setSoftwareList } from "../../redux/reducer/softwareListReducer";
import { getTarget, getToken } from "./sessionStorage";
import lightspeedLogo from '../../images/logo_lightspeed.svg';
import revelLogo from '../../images/logo_revel.png';
import eposLogo from "../../images/epos_logo.png";
import { setToken } from "../../slices/authSlice";
import _ from "lodash";
import ct from "countries-and-timezones"

export const getKeyByPosType = (baseKey) => {

    const posType = getCurrentTarget();

    const posTypeToKey = {
        'lseries': 'LSeries',
        'kseries': 'KSeries',
        'retail': 'Retail',
        'EPOS': 'LSeries',
        'REVEL': 'LSeries',
        'runit': "RunIt"
    };

    return `${baseKey} ${posTypeToKey[posType.name]}`;
};

export const targetLogo = {
    kseries: lightspeedLogo,
    lseries: lightspeedLogo,
    REVEL: revelLogo,
    EPOS: eposLogo
};

export const targetFullNames = {
    kseries: 'Lightspeed',
    lseries: 'Lightspeed',
    REVEL: 'Revel',
    EPOS: "EposNow"
};

export const getCurrentTarget = () => {
    const target = getTarget();
    return {
        name: target,
        fullName: targetFullNames[target],
        logo: targetLogo[target],
        isLs: target === 'kseries' || target === 'lseries',
        isRevel: target === 'REVEL',
        isEposNow: target === 'EPOS',
    }
};

export const moveToNextScreen = (navigate, dispatch, nextPageUrl, resData, initialLocationId, token, selectFirstLocation) => {
    let url = `../select-location`;
    // setToken(resData.token || token);
    dispatch(setToken(resData.token))
    dispatch(setSoftwareList(resData.accountingSoftware));
    const locations = resData.locationList;
    dispatch(setLocationList(locations));
    if (selectFirstLocation || initialLocationId || (locations.length < 2)) {
        url = nextPageUrl;
        const location = initialLocationId ? { id: initialLocationId } : locations[0];
        dispatch(setLocation(location));
    }
    navigate(url);
};

export const getHeaders = (locationId) => {
    const val = {
        'Authorization': getToken(),
    };

    if (locationId) {
        val.locationId = locationId;
    }

    return val;
};

export const isNumberSame = (first, second) => {
    return `${first}` === `${second}`;
};

export const filterAccountsByKeywords = (allAccounts = [], keywordsList = []) => {
    return _.flatten(keywordsList?.map(keyword => {
        return _.filter(allAccounts, (d) => { return d?.type?.includes(keyword) })
      }))
};

export const getConnectionModeName = (t, connectionMode) => {
    const connectionModeName = {
        'Xero': t('Xero'),
        'Online': t('QuickBooks Online'),
        'Sage One': t('Sage One'),
        'Sage50': t('Sage 50'),
        'Sage100': t('Sage 100'),
        'Sage200': t('Sage 200'),
        'QB Desktop Edition': t('QuickBooks Desktop'),
        'Exact Online': 'Exact Online',
        'Datev': t('Datev'),
        'myOBAccountRightCloud': 'MYOB AccountRight',
    };

    return connectionModeName[connectionMode] || connectionMode;
};

export const getClassObject = (value) => {
    const [id, name] = [value, value];

    return { [id]: name };
};

export const parseClassMapping = (mapping) => {
    const accountId = Object.keys(mapping)[0];
    const name = mapping[accountId];
    return name;
};

export const exist = (value) => {
    return !_.isNil(value)
}

export const getTZ = (country) => {

    if (country === '' || country === undefined || country === null) {
        return []
    }

    switch (country) {
      case "US":
        return [
          "America/EDT",
          "America/CDT",
          "America/MDT",
          "America/MST",
          "America/PDT",
          "America/AKDT",
          "America/HDT",
          "America/HST",
        ]
      case "CA": 
        return [
          "Canada/PDT",
          "Canada/MST",
          "Canada/MDT",
          "Canada/CST",
          "Canada/CDT",
          "Canada/EST",
          "Canada/EDT",
          "Canada/AST",
          "Canada/ADT",
          "Canada/NDT",
        ]
    case "MX":
      return [
        "Mexico/MST",
        "Mexico/PDT",
        "Mexico/CST",
        "Mexico/MDT",
        "Mexico/EST",
        "Mexico/CDT",
      ]
    case "AU":
        return [
          "Australia/AWST",
          "Australia/ACWST",
          "Australia/ACST",
          "Australia/AEST",
          "Australia/LHST",
        ]
      default:
        return ct.getCountry(country)?.timezones
    }

}
