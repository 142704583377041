import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Label } from '@lightspeed/flame/FormField';
import { LabelRequired } from '../custom/LabelRequired';
import { TextInput } from '../fields';
import { useTranslation } from 'react-i18next';
import { OverShort } from '../overShort/OverShort';
import { Divider } from '@lightspeed/flame/Divider';
import { LayAway } from '../Layaway/LayAway';
import { useSelector } from 'react-redux';
import { PettyCashPayouts } from '../pettyCashPayouts/PettyCashPayouts';
import { getCurrentTarget } from '../../utils/helpers/commonHelpers';
import useConnectionMode from '../../hooks/useConnectionMode';
import amIVisible from '../../componentControl/registry';
import { ClassTracking } from '../locationSection/classTracking';
import { MultipleClass } from '../locationSection/MultipleClass';
import { FloorMapping } from '../Floors/floor';
import { Adjustment } from '../mapAdjustment/Adjustment';
import { LocationMapping } from '../locationSection/LocationMapping';
import { RecordingFee } from '../mapRecordingFee/RecordingFee';

const CustomLabel = ({ label, required, ...props }) => {
  const LabelComp = required === true ? LabelRequired : Label;
  return <LabelComp
    descriptionProps={{ marginTop: '0.375rem' }}
    {...props}
  >
    {label}
  </LabelComp>;
};

const HalfBox = ({ children }) => {
  return <Box width="50%" ml="0.75rem" mr="0.75rem">
    {children}
  </Box>;
};

const OptionsMapping = ({ formik }) => {
  const isLoading = false;
  const target = getCurrentTarget();
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();
  const canShowLayAway = useSelector(state => state.globals.canShowLayAway) && amIVisible("layaway");
  const canShowCustomerName = amIVisible("default customer name")
  const canShowTxnNumber = amIVisible("transaction number")

  return <Box mt="0.75rem" mb="0.75rem">
    <Flex>
      {canShowCustomerName && <HalfBox>
        <CustomLabel
          label={t('Default customer name')}
          description={t('DefaultNameDescription', { swName })}
          htmlFor="defaultCustomer"
          required={true}
        />
      </HalfBox>}
      {canShowTxnNumber && <HalfBox>
        <CustomLabel
          label={t('Custom transaction number')}
          description={t('CustomerTransactionDescription', { target: target.fullName })}
          htmlFor="customTransactionNumber"
        />
      </HalfBox>}
    </Flex>
    <Flex>
      {canShowCustomerName && <HalfBox>
        <TextInput
          isLoading={isLoading}
          id="defaultCustomer"
          name="defaultCustomer"
          placeholder={"POS customer"}
        />
      </HalfBox>}
      {canShowTxnNumber && <HalfBox>
        <TextInput
          isLoading={isLoading}
          id="customTransactionNumber"
          name="customTransactionNumber"
          placeholder={t('Enter the custom transaction number')}
        />
      </HalfBox>}
    </Flex>
    <>
      {
        canShowLayAway && (
          <>
            <Divider mt={"1rem"} />
            <LayAway formik={formik} />
          </>
        )
      }
    </>
  </Box>
};

const OptionsSectionContent = (props) => {
  const [swName] = useConnectionMode();
  let canShowLocationClassTracking = amIVisible("location / class tracking")
  let canShowLocationMapping = amIVisible("location mapping")
  let canShowClassMapping = amIVisible("class mapping")
  const canShowOverShort = amIVisible("over short")
  const canShowPettyCashPayments = amIVisible("petty cash payouts")
  const canShowFloorMapping = amIVisible("floor mapping")
  let canShowAdjustmentMapping = amIVisible("adjustment")
  let canShowRecordingProcessingFeeMapping = amIVisible("MapRecordingProcessingFee")

  let LocationSection = <></>

  switch (swName) {
    case "Xero":
      if (canShowLocationMapping)
        LocationSection = LocationMapping
      break
    case "QB Desktop Edition":
      if (canShowClassMapping)
        LocationSection = ClassTracking
      break
    default:
      if (canShowLocationClassTracking)
        LocationSection = MultipleClass
      break
  }


  return <>
    <Card ml="1rem">
      <CardSection>
        <OptionsMapping {...props} />
      </CardSection>
    </Card>
    {
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          <LocationSection {...props} />
        </CardSection>
      </Card>
    }
    {
      canShowOverShort && 
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          <OverShort {...props} />
        </CardSection>
      </Card>
    }
    {canShowFloorMapping &&
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          <FloorMapping {...props} />
        </CardSection>
      </Card>
    }
    {canShowAdjustmentMapping &&
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          <Adjustment {...props} />
        </CardSection>
      </Card>
    }
    {canShowPettyCashPayments &&
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          <PettyCashPayouts {...props} />
        </CardSection>
      </Card>}
    {canShowRecordingProcessingFeeMapping &&
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          <RecordingFee {...props} />
        </CardSection>
      </Card>}
  </>;
};

export const OptionsSection = (props) => {
  const { t } = useTranslation();

  return <AnnotatedLayout
    title={t('Additional Settings')}
    mt="2.25rem"
    mb="2.25rem"
  >
    <OptionsSectionContent {...props} />
  </AnnotatedLayout>;
};
