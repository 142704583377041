import { useState } from 'react';
import { Text } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Flex, Box } from '@lightspeed/flame/Core';
import { useToasts } from '@lightspeed/flame/Toaster';
import { Button } from '@lightspeed/flame/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useRole from '../../hooks/useRole';
import { useLocale } from '../../hooks/useLocale';
import { useHistoricalSyncMutation } from '../../services/syncService';
import { useNotification } from '../../utils/helpers/notifications';
import { useLocationData } from '../../hooks/useLocation';

const toDateString = (locale, dateObj) => {
  return dateObj?.toISOString()?.slice(0, 10);
};

const createPayload = (startDate, endDate, userType, locale, isTypePo) => {
  return {
    startDate: toDateString(locale, startDate),
    endDate: toDateString(locale, endDate),
    userType,
    syncPO: isTypePo,
  }
};

export const HistoricalSync = ({ isTypePo }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().getTime() - 24*60*60*1000));
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const [userRole] = useRole();
  const locale = useLocale();
  const { currentLocation } = useLocationData()
  const appInstalledOn = currentLocation?.appInstalledOn;
  const [startHistoricalSync] = useHistoricalSyncMutation()
  const { showNotification } = useNotification()

  const startSync = () => {
    setIsLoading(true);

    const payload = createPayload(startDate, endDate, userRole.type, locale, isTypePo);

    startHistoricalSync(payload).unwrap().then(res => {
      showNotification("Request has been queued up for processing.", "success")
    }).catch((err) => {
        if (err?.data?.error?.key === "incompleteConfiguration") {
          addToast("Complete Configuration First", { appearance: "error" })
        } else if (err?.data?.error?.key === "NoDataToQueue") {
          addToast(`No EOD performed in the specific date range from ${new Date(startDate)?.toISOString()?.split('T')[0]} to ${new Date(endDate)?.toISOString()?.split('T')[0]}`, { appearance: "error" })
        } else if (err?.data?.error?.key === "PONotEnabled") {
          addToast("Enable PO To Sync", { appearance: "error" })
        } else {
          addToast(err?.data?.error?.key ?? "Something went wrong", { appearance: "error" })
        }
    }).then(() => {
      setIsLoading(false);
    });
  };

  const validateBeforeSync = () => {
    let isValid = false;

    if (startDate.getFullYear() < endDate.getFullYear()) {
      isValid = true;
    } else if (startDate.getFullYear() === endDate.getFullYear()) {
      if (startDate.getMonth() < endDate.getMonth()) {
        isValid = true;
      } else if (startDate.getMonth() === endDate.getMonth()) {
        isValid = startDate.getDate() <= endDate.getDate();
      }
    }

    if (isValid) {
      startSync();
    } else {
      addToast('Invalid date range', { appearance: 'error' });
    }
  };

  return <Card mb="1.5rem">
    <CardSection>
      <Text mb="0.375rem" fontWeight="bold">Historical Sync</Text>
      <Flex alignItems="flex-end">
        <Box>
          <Text>Start</Text>
          <DatePicker dateFormat={"yyyy-MM-dd"} selected={startDate} onChange={date => setStartDate(date)} minDate={new Date(appInstalledOn)} maxDate={new Date(new Date().getTime() - 24*60*60*1000)} />
        </Box>
        <Box ml="1rem">
          <Text>End</Text>
          <DatePicker dateFormat={"yyyy-MM-dd"} selected={endDate} onChange={date => setEndDate(date)} minDate={new Date(appInstalledOn)} maxDate={new Date(new Date().getTime() - 24*60*60*1000)} />
        </Box>
        <Button
          loading={isLoading}
          fill={true}
          variant="secondary"
          ml="1rem"
          onClick={validateBeforeSync}
        >
          Start Sync
        </Button>
      </Flex>
    </CardSection>
  </Card>;
};
