import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import env from "../env.json";
import portal from "../target.json";

const baseQuery = (args, api, extraOptions) => {
    const token = (api.getState()).auth.token
    const locationid = (api.getState()).auth.locationid
    const query = fetchBaseQuery({
        baseUrl: env["REACT_APP_SERVER_URL_" + portal.target],
        prepareHeaders: (headers) => {
            headers.append("Authorization", token)
            headers.append("locationid", locationid)
        }
    })

    return query(args, api, extraOptions);
}

export const apiService = createApi({
    reducerPath: "apiService",
    baseQuery,
    endpoints:  (builder) => ({
        getConfig: builder.query({
            query: () => 'configuration'
        })
    })
});

export const { useGetConfigQuery } = apiService;